/* AnotherCarousel.css */
.body{
  height: 60%;
}
.text{
    color: #185bf6;
    text-transform: capitalize;
    font-style: italic;
    font-size: 2em;
    
}
.custom-carousel {
    border-radius: 15px;
    overflow: hidden;
  }
  
  .custom-image {
    object-fit: cover;
    max-height: 45%;
    width: 100%; 
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: #3498db; /* Adjust background color as needed */
  }
  
  .carousel-indicators {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
  }
  
  .carousel-indicators li {
    background-color: #3498db; /* Adjust background color as needed */
    width: 15px;
    height: 15px;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .carousel-indicators .active {
    background-color: #fff; /* Adjust background color for active indicator as needed */
    border: 1px solid #3498db; /* Adjust border color for active indicator as needed */
  }
  