/* Awards.css */

.awards-container {
    background-color: #f8f8f8;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.awards-title {
    color: #333;
    text-align: center;
    justify-content: center;
    font-size: 1.4rem;
}

.awards-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.awards-details {
    padding: 20px;
}

.awards-subtitle {
    color: #ff5e00;
    text-transform: capitalize;
    font-style: italic;
    font-size: 2.8em;
}

.awards-description {
    color: #555;
    line-height: 1.6;
}
