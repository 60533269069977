.body{
    /* color: #333; */
    /* font-size: 14px;
    font-weight: 400;
    line-height: 1.6; */
    /* position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; */

}
.pic{
    background-color: #5e43ca;
    color: aqua;
    display: block;
    /* width: 100% \9; */
    max-width: 100%;
    height: auto;
    border-radius: 75%;
    background-position:calc(50% + 4.59752px);
}

    p {
        text-align:justify ;
        /* display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px; */
    }
    .h {
        color:#a9520b;
        /* margin: 25px; */
        /* padding-right: 700px ; */
        /* font-family: inherit; */
       /* font-weight: 500; */
        /* line-height: 1.1; */
        /* display: block; */
        /* font-size: 1.1em; */
        text-align:justify ;
        display: block;
        /* margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px; */
        /* font-weight: bold; */
    }
    h4{
        color: #333;
    font-size: 1px;
    font-weight: 400;
    margin-bottom: 40px;
    margin: 25px;
        padding-right: 719px ;
        font-family: inherit;
       /* font-weight: 500; */
        line-height: 1.1;
        /* display: block; */
        font-size: 1.17em;
        /* margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px; */
    }
.icon{
    text-align: center;
}
